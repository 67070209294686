.cocodi_title{
    text-align: center;
    padding-bottom: 20px;
}
.card-header{
     
    font-size: 20px;
    padding-bottom: 15px;
}
.cocodi_title h1{
    color: var(--wine);
    font-family: Montserrat-Bold;
}
.cocodi-container{
    padding-top: 50px;
    margin-bottom: 50px;

}
.cocodi_item a{
    color: var(--gold);
    font-size: 20px;
    text-decoration: none;
    display: flex;
    justify-content: center;
}
.cocodi-container li{
    color:var(--wine);
}
.accordion-button {
    background-color: var(--wine)!important;
    color: white !important;    
}

@media (max-width: 576px){
    .cocodi-container{
        min-height: 600px;
    }
    .cocodi_item a{
        padding: 10px 0;
    }
}
@media (min-width:577px) and (max-width:820px){
    .cocodi-container{
        min-height: 600px;
    }
}
.arccordion-gris {
    background-color:#F4F4F4!important;
    font-size: 1rem;
    color: #404345!important;
    font-size: 20px;
    font-family: Montserrat-Bold;
    font-weight: bold;
}
.bg-secondary-dos-pdf{
    background-color:#730505!important;
    float: right;
}
.espacio {
    margin-bottom: 20px;
}

