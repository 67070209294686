.card-content-anio{
    background-color: var(--whiteBackground);
    border-radius: 10px;
    width: 100%;
    height: 400px;
    overflow-x: scroll;
    margin-bottom: 50px;
}
.card-content-anio .card_trimestre .span-excel{
    background-color: #287233 !important;
}

.card-content-anio .card_trimestre .span-pdf{
    margin-right: 10px;
    background-color: var(--wine) !important;
}
.card-content-anio .card-body h5{
    color: var(--pink);
    font-family: Montserrat-SemiBold;
}
@media (max-width:576px) {
    .card-content-anio{
        height: 350px;
        margin-top: 30px;
    }
}