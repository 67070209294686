.csocial_title{
    text-align: left;
    padding-top: 30px;
    margin-bottom: 30px;
}
.csocial_title h1{
    color: var(--wine);
    font-family: Montserrat-Bold;
}
.csocial-container{
    padding-top: 10px;
    margin-bottom: 10px;
}
.csocial_item a{
    color: var(--gold);
    font-size: 20px;
    text-decoration: none;
    display: flex;
    justify-content: left;
}
@media (max-width: 576px){
    .csocial-container{
        min-height: 600px;
    }
    .csocial_item a{
        padding: 10px 0;
    }
}
@media (min-width:577px) and (max-width:820px){
    .csocial-container{
        min-height: 600px;
    }
}