.sistemainstitucionalarchivos_title{
    text-align: center;
    padding-bottom: 20px;
}
.sistemainstitucionalarchivos_title h1{
    color: var(--wine);
    font-family: Montserrat-Bold;
}
.sistemainstitucionalarchivos-container{
    padding-top: 50px;
    margin-bottom: 50px;
}
.sistemainstitucionalarchivos_item a{
    color: var(--gold);
    font-size: 20px;
    text-decoration: none;
    display: flex;
    justify-content: left;
}
@media (max-width: 576px){
    .sistemainstitucionalarchivos-container{
        min-height: 600px;
    }
    .sistemainstitucionalarchivos_item a{
        padding: 10px 0;
    }
}
@media (min-width:577px) and (max-width:820px){
    .sistemainstitucionalarchivos-container{
        min-height: 600px;
    }
}