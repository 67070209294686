.paaas_title{
    text-align: center;
    padding-bottom: 20px;
}
.paaas_title h1{
    color: var(--wine);
    font-family: Montserrat-Bold;
}
.paaas-container{
    padding-top: 50px;
    margin-bottom: 50px;
}
.paaas_item a{
    color: var(--green);
    font-size: 20px;
    text-decoration: none;
    display: flex;
    justify-content: center;
}
.paaas-container li{
    color:var(--black);
}
.accordion-button {
    background-color: var(--wine)!important;
    color: white !important;    
}
.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
@media (max-width: 576px){
    .paaas-container{
        min-height: 600px;
    }
    .paaas_item a{
        padding: 10px 0;
    }
}
@media (min-width:577px) and (max-width:820px){
    .paaas-container{
        min-height: 600px;
    }
}
.arccordion-gris {
    background-color:#F4F4F4!important;
    font-size: 1rem;
    color: #404345!important;
    font-size: 20px;
    font-family: Montserrat-Bold;
}
.bg-secondary-dos-pdf{
    background-color:#730505!important;
    float: right;
}
.espacio {
    margin-bottom: 20px;
}
.paaas-cuadro h1{
    color: black!important;
    font-family: Montserrat-Bold;
}