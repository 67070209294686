.error_container {
    padding-top: 150px;
    margin-bottom: 150px;
    min-height: 200px;
}
.error_container h1{
    color: var(--wine);
    font-family: Montserrat-Bold;
}
.error_container p{
    color: var(--pink);
}
.error_container .btn_error{
    background-color: var(--wine);
    color: #fff;
    border-radius: 10px;
    padding: 2px 10px;
    font-family: Montserrat-Bold;
}
@media (min-width: 577px) and (max-width: 820px){
    .error_container{
        min-height: 500px;
    }
}