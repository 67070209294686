.container-armon{
    padding: 20px;
    background-color: var(--whiteBackground);
    border-radius: 15px;
    margin-bottom: 30px;
}
.multi-collapse{
    margin-left: 40px;
}
.contenido-button-enlace{
    margin-bottom: 15px;
}
.armo-collapse{
    margin-left: 20px;
    margin-bottom: 20px;
}
.data-armo-list{
    overflow-x: scroll;
    background-color: var(--whiteBackground);
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    height: 450px;
}
@media (max-width:576px) {
    .data-armo-list{
        height: 200px;
    }
}
